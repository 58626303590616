<template>
  <div class="page-table mainDiv mb-30" id="affix-container">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.yonetici.tanimlama.title") }}
    </div>
    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInUp" v-loading="loading"
         :element-loading-text='$t("src.views.apps.yonetici.tanimlama.addLoading")'
         element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(255, 255, 255, 1)">
      <el-form status-icon :model="yoneticiForm" :rules="rulesYonetici" ref="yoneticiForm" class="demo-ruleForm">
        <div class="card-base card-shadow--medium animated fadeInUp">
          <el-row type="flex" justify="center">
            <el-col :lg="4" :md="4" :sm="20" :xs="20" class="col-p"
                    style="display: flex !important; justify-content: center !important; padding-top: 15px !important">
              <el-form-item prop="image">
                <el-upload
                    id="yoneticiekle-resim"
                    action=""
                    :auto-upload="false"
                    :limit="1"
                    :file-list="imageListMain"
                    :on-change="imageUpload"
                    :on-remove="imageRemove"
                    accept=".jpg,.jpeg,.png"
                    ref="upload"
                    list-type="picture-card">
                  <i slot="default" class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :lg="10" :md="10" :sm="24" :xs="24" class="col-p">
              <el-col v-if="yoneticiForm.tur != '4'" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item>
                  <span>{{ $t("src.views.apps.yonetici.tanimlama.tcOrEposta") }}</span>
                </el-form-item>
              </el-col>
              <el-col v-if="yoneticiForm.tur != '4'" :lg="8" :md="8" :sm="8" :xs="24" class="col-p">
                <el-form-item>
                  <el-switch
                      v-model="yoneticiForm.switch"
                      active-value="1"
                      inactive-value="0"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      :active-text='$t("src.views.apps.yonetici.tanimlama.switchActiveText")'
                      :inactive-text='$t("src.views.apps.yonetici.tanimlama.switchInactiveText")'>
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col v-if="yoneticiForm.tur != '4'" :lg="16" :md="16" :sm="16" :xs="24" class="col-p">
                <el-form-item
                    :label='$t("src.views.apps.yonetici.tanimlama.kullaniciAdi")'
                    prop="kullaniciAdi">: &nbsp;
                  {{ yoneticiForm.switch === '1' ? yoneticiForm.eposta : yoneticiForm.tcNo }}
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item
                    :label='$t("src.views.apps.yonetici.tanimlama.yetki")'
                    prop="tur">:
                  <el-select
                      size="small"
                      v-model="yoneticiForm.tur"
                      style="width: 100%">
                    <el-option value="1" label="Üst Yönetici" v-if="yetkiTuru == 1"></el-option> <!--TAM YETKİLİ-->
                    <el-option value="2" label="Birim Yöneticisi" v-if="yetkiTuru == 1"></el-option><!--SINIRLI YETKİ-->
                    <el-option value="3" label="Kullanıcı"></el-option>
                    <el-option value="4" label="Çalışan"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p"
                      v-if="yoneticiForm.tur == 2 || yoneticiForm.tur == 3">
                <el-form-item
                    :label='$t("src.views.apps.yonetici.tanimlama.BG")'
                    prop="sozlesmeKategoriID">:
                  <el-select
                      size="small"
                      v-model="yoneticiForm.sozlesmeKategoriID"
                      style="width: 100%">
                    <el-option
                        v-for="item in kategoriler"
                        :key="item.sozlesmeKategoriID"
                        :label="item.sozlesmeKategoriAdi"
                        :value="item.sozlesmeKategoriID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item
                    :label='$t("src.views.apps.yonetici.tanimlama.adSoyad")'
                    prop="adSoyad">:
                  <el-input
                      size="small"
                      v-model="yoneticiForm.adSoyad"
                      :placeholder='$t("src.views.apps.messages.enterNameSurname")'></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.tcNo")' prop="tcNo">:
                  <el-input
                      size="small"
                      v-model="yoneticiForm.tcNo" v-mask="'###########'"
                      :placeholder='$t("src.views.apps.messages.enterTCNo")'></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.cepTelefon")' prop="cepTelefon">:
                  <el-input
                      size="small"
                      v-model="yoneticiForm.cepTelefon"
                      v-mask="'+90(###)### ## ##'"
                      placeholder="+90(555)555 55 55"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.telefonSabit")' prop="sabitTelefon">:
                  <el-input
                      size="small"
                      v-model="yoneticiForm.sabitTelefon"
                      v-mask="'+90(###)### ## ##'"
                      placeholder="+90(346)555 55 55"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.dahili")' prop="dahili">:
                  <el-input
                      size="small"
                      v-model="yoneticiForm.dahili" v-mask="'######'"
                      placeholder="555555"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item
                    :label='$t("src.views.apps.yonetici.tanimlama.email")'
                    prop="eposta">:
                  <el-input type="email"
                            size="small"
                            v-model="yoneticiForm.eposta"
                            placeholder="example@gmail.com"></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item
                    :label='$t("src.views.apps.messages.enterMailAgain")' prop="eposta2">:
                  <el-input type="email"
                            size="small"
                            v-model="yoneticiForm.eposta2"
                            placeholder="example@gmail.com"></el-input>
                </el-form-item>
              </el-col>
              <el-col v-if="yoneticiForm.tur != '4'" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <el-form-item>
                  <el-checkbox v-model="sifreGiris">Şifreyi Sms Olarak Gönder</el-checkbox>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="!sifreGiris">
                <el-form-item :label='$t("src.views.apps.yonetici.tanimlama.password")' prop="sifre">:
                  <el-input type="password"
                            size="small"
                            v-model="yoneticiForm.sifre"
                            :placeholder='$t("src.views.apps.messages.enterPass")'></el-input>
                </el-form-item>
                <password v-model="yoneticiForm.sifre" :strength-meter-only="true"/>
              </el-col>
              <el-col style="float: right" :lg="24" :md="24" :sm="24" :xs="24" class="col-p" v-if="!sifreGiris">
                <el-form-item :label='$t("src.views.apps.messages.enterPassAgain")' prop="sifre2">:
                  <el-input type="password"
                            size="small"
                            v-model="yoneticiForm.sifre2"
                            :placeholder='$t("src.views.apps.messages.enterPassAgain")'></el-input>
                </el-form-item>
              </el-col>
              <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                <div style="display: flex; justify-content:end; margin-bottom: 10px">
                  <el-form-item>
                    <el-popover placement="top" width="300" v-model="visibleSil">
                      <p>{{ $t('src.views.apps.messages.clearFormAlert') }}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="primary"
                                   @click="resetForm('yoneticiForm')">
                          {{ $t('src.views.apps.genel.yes') }}
                        </el-button>
                        <el-button size="mini" @click="visibleSil = false;">
                          {{ $t('src.views.apps.genel.no') }}
                        </el-button>
                      </div>
                      <el-button size="small" slot="reference" class="mr-10">
                        {{ $t('src.views.apps.genel.temizle') }}
                      </el-button>
                    </el-popover>
                    <el-button v-if="sifreGiris" v-on:click="yoneticiTanimlama2('yoneticiForm')" size="small"
                               type="primary">
                      {{ $t('src.views.apps.genel.tanimla') }}
                    </el-button>
                    <el-button v-if="!sifreGiris" v-on:click="yoneticiTanimlama('yoneticiForm')" size="small"
                               type="primary">
                      {{ $t('src.views.apps.genel.tanimla') }}
                    </el-button>
                  </el-form-item>
                </div>
              </el-col>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import yoneticiService from '../../../WSProvider/YoneticiService'
import Password from 'vue-password-strength-meter'
import notification from '../../../notification'
import functions from '../../../functions'
import JQuery from 'jquery';
import EventBus from "@/components/event-bus";
import sozlesmeService from '../../../WSProvider/SozlesmeService'

//import md5 from 'crypto-js/md5';

let $ = JQuery;

export default {
  name: "PersonelTanimla",
  components: {Password},
  created() {
    this.yetkiTuru = this.$store.getters.getYetkiTuru;
  },
  mounted() {
    this.sozlesmeKategoriListesi();
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('src.views.apps.messages.enterPass')));
      } else {
        if (this.yoneticiForm.sifre2 !== '') {
          this.$refs.yoneticiForm.validateField('sifre2');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('src.views.apps.messages.enterPassAgain')));
      } else if (value !== this.yoneticiForm.sifre) {
        callback(new Error(this.$t('src.views.apps.messages.passNotMatch')));
      } else {
        callback();
      }
    };
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('src.views.apps.messages.enterMail')));
      } else {
        if (this.yoneticiForm.eposta2 !== '') {
          this.$refs.yoneticiForm.validateField('email2');
        }
        callback();
      }
    };
    var validateEmail2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('src.views.apps.messages.enterMailAgain')));
      } else if (value !== this.yoneticiForm.eposta) {
        callback(new Error(this.$t('src.views.apps.messages.mailNotMatch')));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      visibleSil: false,
      kategoriler: [],
      imageListMain: [],
      yetkiTuru: "",
      sifreGiris: true,
      yoneticiForm: {
        switch: "1",
        yonetimKodu: '',
        sozlesmeKategoriID: '',
        sozlesmeKategoriAdi: '',
        tur: '',
        tcNo: '',
        image: '',
        imageBase: '',
        kullaniciAdi: '',
        adSoyad: '',
        eposta: '',
        eposta2: '',
        cepTelefon: '',
        sabitTelefon: '',
        dahili: '',
        sifre: '',
        sifre2: '',

        loading: false,
      },
      rulesYonetici: {
        tur: [{
          required: true,
          message: "Lütfen Yetki Türü Seçiniz.",
          trigger: 'blur'
        }],
        cepTelefon: [{
          required: true,
          message: "Lütfen Cep Telefonu Giriniz.",
          trigger: 'blur'
        }],
        sozlesmeKategoriID: [{
          required: yoneticiForm => yoneticiForm.tur == '2' || yoneticiForm.tur == '3',
          message: 'Lütfen Birim Seçiniz.',
          trigger: 'blur'
        }],
        adSoyad: [{
          required: true,
          message: this.$t('src.views.apps.messages.enterNameSurname'),
          trigger: 'blur'
        }],
        tcNo: [
          {required: true, message: "Lütfen T.C. Kimlik Numaranızı Giriniz.", trigger: 'blur'}
        ],
        eposta: [
          {type: 'email', message: this.$t('src.views.apps.messages.enterValidMail'), trigger: 'blur'},
          {required: true, validator: validateEmail}
        ],
        eposta2: [
          {required: true, validator: validateEmail2, trigger: 'blur'}
        ],
        /*
        sifre: [
            {required: true, validator: validatePass, trigger: 'blur'}
        ],
        sifre2: [
            {required: true, validator: validatePass2, trigger: 'blur'}
        ],   */
      },
      rulesYonetici2: {
        tur: [{
          required: true,
          message: "Lütfen Yetki Türü Seçiniz.",
          trigger: 'blur'
        }],
        cepTelefon: [{
          required: true,
          message: "Lütfen Cep Telefonu Giriniz.",
          trigger: 'blur'
        }],
        sozlesmeKategoriID: [{
          required: yoneticiForm => yoneticiForm.tur == '2' || yoneticiForm.tur == '3',
          message: 'Lütfen Birim Seçiniz.',
          trigger: 'blur'
        }],
        adSoyad: [{
          required: true,
          message: this.$t('src.views.apps.messages.enterNameSurname'),
          trigger: 'blur'
        }],
        tcNo: [
          {required: true, message: "Lütfen T.C. Kimlik Numaranızı Giriniz.", trigger: 'blur'}
        ],
        eposta: [
          {type: 'email', message: this.$t('src.views.apps.messages.enterValidMail'), trigger: 'blur'},
          {required: true, validator: validateEmail}
        ],
        eposta2: [
          {required: true, validator: validateEmail2, trigger: 'blur'}
        ],
        sifre: [
          {required: true, validator: validatePass, trigger: 'blur'}
        ],
        sifre2: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    resetForm(formName) {
      this.$refs[formName].resetFields();
      functions.sayfaKapat("yoneticitanimla", this)
    },
    imageRemove() {
      $('#yoneticiekle-resim .el-upload--picture-card').show();
      this.imageListMain = [];
      this.yoneticiForm.image = [];
    },
    imageUpload(file) {
      this.imageListMain = []
      this.yoneticiForm.image = []
      if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
        var item = {
          name: file.name,
          url: file.url,
          id: file.raw.lastModified,
          size: file.raw.size
        };
        this.imageListMain.push(item)

        file.raw["tmp_name"] = file.url; // backend file_url'i tmp_name değişkeninden alıyor

        this.yoneticiForm.image.push(file.raw)
        $('#yoneticiekle-resim .el-upload--picture-card').hide();
      }
    },
    sozlesmeKategoriListesi() {
      try {
        this.loading = true;
        sozlesmeService.sozlesmeKategoriListesi().then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          if (response.status == 200) {
            this.kategoriler = response.data;
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.kategoriler = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false
      }
    },
    yoneticiTanimlama(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm(this.$t('src.views.apps.messages.warningAddAdmin'), this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            try {
              this.loading = true;
              this.kullaniciAdi = this.yoneticiForm.switch == 1 ? this.yoneticiForm.eposta : this.yoneticiForm.tcNo
              //const md5 = require('md5')
              //const sifre = md5(this.yoneticiForm.sifre)

              yoneticiService.yoneticiTanimlama("HBT", this.yoneticiForm.tur, this.yoneticiForm.sozlesmeKategoriID, this.yoneticiForm.tcNo, this.yoneticiForm.image.length > 0 ? this.yoneticiForm.image[0] : "", this.kullaniciAdi, this.yoneticiForm.sifre, this.yoneticiForm.adSoyad, this.yoneticiForm.tur == "1" ? "Üst Yönetici" : this.yoneticiForm.tur == "2" ? "Birim Yöneticisi" : "Personel", this.yoneticiForm.eposta, this.yoneticiForm.cepTelefon, this.yoneticiForm.sabitTelefon, this.yoneticiForm.dahili).then(response => {
                if (response.status === 200) {
                  localStorage.setItem("userDataBGSurec", response.token)
                  notification.Status("success", this, this.$t('src.views.apps.messages.adminAddSuccess'))
                  EventBus.$emit("yoneticiList", true)
                  this.resetForm(formName)
                  functions.sayfaKapat("personeltanimla", this);
                  functions.routeSayfa("Personel Listesi", this);
                }
                this.loading = false;
              }).catch(err => {
                if (err.responseJSON) {
                  let error = err.responseJSON
                  if (error.status === 401) {
                    notification.Status(150, this);
                  } else notification.Status("errorMsg", this, error.errMsg)
                } else {
                  //notification.Status(503, this)
                }
                this.loading = false;
              })
            } catch (e) {
              // console.log(e);
              notification.Status("warning", this, this.$t('src.views.apps.messages.errorAddAdmin'))
            }
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },
    yoneticiTanimlama2(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm(this.$t('src.views.apps.messages.warningAddAdmin'), this.$t("src.views.apps.genel.uyari"), {
            confirmButtonText: this.$t("src.views.apps.genel.yes"),
            cancelButtonText: this.$t("src.views.apps.genel.no"),
            type: 'warning'
          }).then(() => {
            try {
              this.loading = true;
              this.kullaniciAdi = this.yoneticiForm.switch == 1 ? this.yoneticiForm.eposta : this.yoneticiForm.tcNo
              //const md5 = require('md5')
              //const sifre = md5(this.yoneticiForm.sifre)

              yoneticiService.yoneticiTanimlama2("HBT", this.yoneticiForm.tur, this.yoneticiForm.sozlesmeKategoriID, this.yoneticiForm.tcNo, this.yoneticiForm.image.length > 0 ? this.yoneticiForm.image[0] : "", this.kullaniciAdi, this.yoneticiForm.adSoyad, this.yoneticiForm.tur == "1" ? "Üst Yönetici" : this.yoneticiForm.tur == "2" ? "Birim Yöneticisi" : "Personel", this.yoneticiForm.eposta, this.yoneticiForm.cepTelefon, this.yoneticiForm.sabitTelefon, this.yoneticiForm.dahili).then(response => {
                if (response.status == 200) {
                  localStorage.setItem("userDataBGSurec", response.token)
                  notification.Status("success", this, this.$t('src.views.apps.messages.adminAddSuccess'))
                  EventBus.$emit("yoneticiList", true)

                  this.resetForm(formName)
                  functions.sayfaKapat("personeltanimla", this);
                  functions.routeSayfa("Personel Listesi", this);
                }
                this.loading = false;
              }).catch(err => {
                if (err.responseJSON) {
                  let error = err.responseJSON
                  if (error.status == 401) {
                    notification.Status(150, this);
                  } else notification.Status("errorMsg", this, error.errMsg)
                } else {
                  //notification.Status(503, this)
                }
                this.loading = false;
              })
            } catch (e) {
              // console.log(e);
              notification.Status("warning", this, this.$t('src.views.apps.messages.errorAddAdmin'))
            }
          })
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            })
            this.loading = false
          }
        }
      })
    },
    routeYonetici(title, scope) {
      if (scope) {
        this.$store.commit('changeUpdateKodu', scope);
      }
      functions.routeSayfa(title, this);
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

.aciklamaSpan {
  position: absolute;
  top: 25px;
}

.page-table {
  &.overflow {
    overflow: hidden;
  }

  .table-box {
    overflow: hidden;
  }
}

.el-form-item {
  font-weight: bold;
}

</style>
<style lang="scss">
#yoneticiekle-resim {
  .el-upload-list__item {
    transition: none !important;
  }
}
</style>